import { ErrorBoundary, Show } from "solid-js";
import { RouteSectionProps } from "@solidjs/router";
import Layout from "~/components/layout/Layout";
import { APIError } from "~/services/roma-api/errors";
import { GenericError } from "~/components/utility/errors";
import { LiveChatLaunchButton } from "~/components/utility";

export default function Page(props: RouteSectionProps) {
  return (
    <>
      <Layout>
        <ErrorBoundary
          fallback={(err, reset) => {
            return <GenericError error={err} reset={reset} autoReport/>;
          }}
        >
          {props.children}
        </ErrorBoundary>
      </Layout>
      <LiveChatLaunchButton/>
    </>
  );
}
