import { type Component, Suspense } from "solid-js";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import { wrenchScrewdriver } from "solid-heroicons/outline";
import romaLogo from "~/assets/icons/romaLogo.svg";

type SiteMaintenanceBlockerProps = {
  data: {
    title?: string;
    description?: string;
    richText?: string;
    active: boolean;
  };
};

export const SiteMaintenanceBlocker: Component<SiteMaintenanceBlockerProps> = (
  props
) => {
  return (
    <Suspense>
      <div class="flex flex-col items-center justify-center h-screen p-4 relative bg-roma-grey overflow-hidden">
        <div class="border p-4 sm:p-6 max-w-xl bg-white/10 backdrop-blur-lg rounded-2xl flex flex-col shadow-lg z-[999]">
          <div class="border-b flex items-center w-full gap-3 pb-2 mb-4">
            <Icon path={wrenchScrewdriver} class="w-6 h-6" />
            <h2 class="text-lg sm:text-2xl">{props.data?.title}</h2>
          </div>
          <p class="text-sm sm:text-base">{props.data?.description}</p>
          <p
            class="text-sm sm:!text-base mt-3"
            innerHTML={props.data?.richText}
          />
        </div>

        {/* FLOATIES */}
        <Motion.img
          aria-hidden="true"
          src={romaLogo}
          alt=""
          class="absolute opacity-[0.015] w-[80vw] left-10"
          initial={{ transform: "translateY(100vh)" }}
          animate={{
            transform: [
              "translateY(100vh)",
              "translateY(-100vh)",
              "translateY(100vh)",
            ],
          }}
          transition={{ duration: 49, repeat: Infinity, easing: "linear" }}
        />

        <Motion.img
          aria-hidden="true"
          src={romaLogo}
          alt=""
          class="absolute opacity-[0.015] w-[30vw] left-[20%]"
          initial={{ transform: "translateY(100vh)" }}
          animate={{ transform: "translateY(-100vh)" }}
          transition={{
            duration: 19,
            repeat: Infinity,
            easing: "linear",
            delay: 7,
          }}
        />

        <Motion.img
          aria-hidden="true"
          src={romaLogo}
          alt=""
          class="absolute opacity-[0.015] w-[45vw] right-10"
          initial={{ transform: "translateY(100vh)" }}
          animate={{ transform: "translateY(-100vh)" }}
          transition={{
            duration: 23,
            repeat: Infinity,
            easing: "linear",
            delay: 13,
          }}
        />

        <Motion.img
          aria-hidden="true"
          src={romaLogo}
          alt=""
          class="absolute opacity-[0.015] w-[60vw] right-[15%]"
          initial={{ transform: "translateY(100vh)" }}
          animate={{ transform: "translateY(-100vh)" }}
          transition={{
            duration: 27,
            repeat: Infinity,
            easing: "linear",
            delay: 17,
          }}
        />
      </div>
    </Suspense>
  );
};
