import { Component, For, onMount, onCleanup, Show } from "solid-js";
import { isServer } from "solid-js/web";
import { A } from "@solidjs/router";

type AnnouncementProps = {
  list: {
    data: {
      copy: string;
      buttonUrl: string;
      buttonLabel: string;
      linkAltText?: string;
    };
  }[];
};

export const Announcements: Component<AnnouncementProps> = (props) => {
  let containerRef: HTMLElement;
  let current = 0;
  let intervalId: ReturnType<typeof setInterval>;

  const scroll = () => {
    current += 1;
    if (current > props.list.length - 1) {
      current = 0;
    }

    if (containerRef) {
      const child = containerRef.children[current] as HTMLElement;
      containerRef.scrollTo({
        left: child.offsetLeft,
        behavior: "smooth",
      });
    }
  };

  if (!isServer) {
    onMount(() => {
      intervalId = setInterval(scroll, 10000);

      const handleVisibilityChange = () => {
        if (document.hidden) {
          clearInterval(intervalId);
        } else {
          intervalId = setInterval(scroll, 10000);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      onCleanup(() => {
        clearInterval(intervalId);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      });
    });
  }

  return (
    <div
      id="announcements-bar"
      class="bg-roma-grey text-xs sm:text-sm relative overflow-hidden overlay-horizontal-fades print:hidden"
    >
      <div
        ref={(ref) => (containerRef = ref)}
        class="w-full flex snap-x overflow-x-hidden"
      >
        <For each={props.list}>
          {(announcement) => (
            <div class="snap-center flex flex-col items-center justify-center shrink-0 w-full py-1.5 text-center h-[3.25rem]">
              <div>{announcement.data.copy}</div>
              <A
                class="font-medium underline"
                href={announcement.data.buttonUrl}
              >
                <Show when={announcement.data.linkAltText}>
                  <span class="sr-only">{announcement.data.linkAltText}</span>
                </Show>
                {announcement.data.buttonLabel}
              </A>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}; 