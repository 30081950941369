import { ParentComponent, Show } from "solid-js";
import { createAsync } from "@solidjs/router";
import { useSiteContext } from "~/utils/contexts";
import { getBuilderGlobalData } from "~/services/builder";
import Nav from "../header/Nav";
import Footer from "../Footer";
import { ToastRegion } from "../utility/toast";
import { Announcements } from "../header";
import { RoutingProgressBar } from "../utility";
import { SiteMaintenanceBlocker } from "../utility";

const Layout: ParentComponent = (props) => {
  const {
    headerVisible: [headerVisible],
  } = useSiteContext();
  const global = createAsync(async () => getBuilderGlobalData());

  return (
    <>
      <Show
        when={global()?.maintenanceBlocker?.data?.active}
        fallback={
          <>
            <RoutingProgressBar />
            <div
              id="header-navigation"
              class="sticky top-0 z-20 transition-transform duration-400"
              classList={{
                "-translate-y-full": !headerVisible(),
              }}
            >
              <Nav
                show={() => true} // not even used ?
                structure={
                  global()?.header.data.blocks[0].component.options.navigation
                }
              />
            </div>
            <Show when={global()?.announcements}>
              <Announcements list={global()?.announcements} />
            </Show>

            {props.children}

            <Footer
              navigation={
                global()?.footer.data.blocks[0].component.options.navigation
              }
              jobs={global()?.jobs}
            />
            <ToastRegion />
          </>
        }
      >
        <SiteMaintenanceBlocker data={global()?.maintenanceBlocker.data} />
      </Show>
    </>
  );
};

export default Layout;
