import { Portal } from "solid-js/web";
import { Toast } from "@kobalte/core";
import "./toastRegion.css"

export const ToastRegion = () => {
  return (
    <Portal>
      <Toast.Region>
        <Toast.List class="toast__list"/>
      </Toast.Region>
    </Portal>
  );
};
